/**
 * @typedef  {Object} Broker.Message
 * @property {Date} sentAt
 * @property {number} expiryDelay seconds
 * @property {number} userId
 * @property {number} accountId
 * @property {number} clientId
 * 
 * @property {string} scope
 * @property {string} eventName
 * @property {Object} body
 * @property {Object} error
 */


/**
 * 
 * @callback ackCallback
 * @param {Error|boolean|void} error
 * @returns {void}
 */


/**
 * 
 * @callback listenerCallback
 * @param {Broker.Message} message
 * @param {ackCallback=} ack
 * @returns {Promise<Error|boolean|void>}
 * @returns {void}
 */

let PRIVATE_SCOPE = Symbol('PRIVATE_SCOPE');
let INTERNAL_EMIT = Symbol('INTERNAL_EMIT');

class Broker extends EventEmitter {
	/**
	 * Met en place le socket et ses triggers
	 * 
	 * @param {string} host 
	 */
	static initConnection(host) {
		if (Ingtech.requestedPage == 'login') return;

		if (!Ingtech.cookie.token) return;
		if (!Ingtech.role) return;

		// Crée la connexion au socket
		let socket = io(host, {
			transports: ['websocket'],
			secure: true,
			reconnection: true,
			rejectUnauthorized: false
		});
		this.socket = socket;

		// Authentifie la connexion
		socket.on('connect', () => {
			if (!Ingtech.cookie.token) return socket.disconnect();

			Ingtech.page.emit('user.connected');

			this.authenticate();
		});

		socket.on("connect_error", (...args) => {
			console.log(`Event: Socket connect_error`, args);
		});

		socket.on('disconnect', (reason, ...args) => {
			this.authenticated = false;

			console.log('Event: Socket disconnect', reason, args);


			this[INTERNAL_EMIT]('disconnect');

			Ingtech.page.emit('user.disconnected');

			if (!Ingtech.cookie.token) {
				Ingtech.page.emit('user.unauthenticated');
			}
			// Essaie de the reconnecter a tous les secondes si il y a toujours un token.
			// setTimeout(() => {
			// 	if (Ingtech.cookie.token) {
			// 		socket.connect();
			// 	}
			// }, 1000);
		});

		socket.on('token-invalidated', () => {
			Ingtech.cookie.token = null;
			Ingtech.page.emit('user.unauthenticated');

			socket.disconnect();
		});

		// Envoie les messages entrant aux listeners
		socket.on('message', async (message, ack) => {
			let broker = this.scope(message.scope);

			try {
				let result = await broker[INTERNAL_EMIT](message.eventName, message);

				if (result instanceof Error) console.error(result);

				ack(result);
			} catch (err) {
				console.log('test', err);
				ack(false);
			}
		});

		socket.on('invalidateToken', async () => {
			Ingtech.cookie.token = null;
		});
	}

	static connect() {
		this.socket.connect();
	}

	static authenticate() {
		this.socket.emit('authenticate', { accessToken: Ingtech.cookie.token }, response => {
			this[INTERNAL_EMIT]('authenticated', response.success);
			this.authenticated = response.success;

			if (!response.success) {
				Ingtech.cookie.token = null;
				return console.error(response.error || new Error());
			}

			Ingtech.page.emit('user.authenticated');
		});
	}


	/**
	 * Crée une instance unique du broker avec un scope
	 * Si il y a déjà une instance avec le scope, ça va retourné cette instance.
	 * 
	 * @param {string} scope
	 * @returns {Broker}
	 */
	static scope(scope) {
		if (this.instances[scope]) {
			return this.instances[scope];
		} else {
			return new this(scope);
		}
	}


	/**
	 * Envoie un message au serveur.
	 *
	 * @param {string} scope
	 * @param {string} eventName
	 * @param {any} body
	 * @param {ackCallback=} ack
	 * @returns {Promise<void>}
	 */
	static emit(scope, eventName, body, options = {}, ack = null) {
		if (options instanceof Function) {
			ack = options;
			options = {};
		}

		return new Promise((resolve, reject) => {
			try {
				this.socket.send({
					scope,
					eventName,
					body,
					parentTransactionId: options.from ? options.from.transactionId : options.parentTransactionId
				}, (result) => {
					if (ack) ack(result);

					if (result.success) resolve(result);
					else reject(result.error);
				});
			} catch (err) {
				if (ack) ack(err);

				reject(err);
			}
		});
	}


	/**
	 * Enregistre un listener pour écouter les messages venant du serveur
	 * 
	 * @param {string} scope
	 * @param {string} eventName 
	 * @param {listenerCallback} listener 
	 */
	static on(scope, eventName, listener) {
		if (eventName instanceof Function && listener === undefined) {
			listener = eventName;
			eventName = scope;
			scope = PRIVATE_SCOPE;
		}

		this.scope(scope).on(eventName, listener);

		return this;
	}


	/**
	 * Enregistre un listener pour écouter les erreurs venant du serveur
	 * 
	 * @param {string} scope
	 * @param {string} eventName 
	 * @param {listenerCallback} listener 
	 */
	static error(scope, eventName, listener) {
		let broker = this.scope(scope);

		broker.error(eventName, listener);
	}




	static [INTERNAL_EMIT](...args) {
		this.scope(PRIVATE_SCOPE)[INTERNAL_EMIT](...args);
	}


	/**
	 * Create a scoped instance of the broker
	 * 
	 * @param {string} scope 
	 */
	constructor(scope) {
		super();

		let instances = this.constructor.instances;
		if (instances[scope]) throw new Error('You cannot create multiple instance with the same scope.');

		this._scope = scope || 'default';

		instances[this._scope] = this;

		this._defaultEvent = Symbol('default');
	}


	[INTERNAL_EMIT](...args) {
		if (this.events[args[0]]) {
			return super.emit(...args);
		} else if (this.events[this._defaultEvent]) {
			args[0] = this._defaultEvent;
			return super.emit(...args);
		} else {
			let [eventName, message] = args;

			if (message && message.errors && message.errors.length > 0) {
				console.error(message);
			}
		}
	}



	/**
	 * Crée une instance unique du broker avec un scope
	 * Si il y a déjà une instance avec le scope, ça va retourné cette instance.
	 * 
	 * @param {string} [scope="default"] 
	 */
	scope(scope = 'default') {
		return this.constructor.scope(scope);
	}


	/**
	 * Envoie un message au serveur du même scope que le broker
	 *
	 * @param {string} eventName
	 * @param {any} body
	 * @param {ackCallback=} ack
	 * @returns {Promise<void>}
	 * @memberof Broker
	 */
	emit(eventName, body, options, ack) {
		return this.constructor.emit(this._scope, eventName, body, options, ack);
	}


	/**
	 * Enregistre un listener pour écouter les messages venant du serveur du même scope que le broker.
	 * 
	 * @param {string} eventName 
	 * @param {listenerCallback} listener 
	 */
	on(eventName, listener) {
		super.on(eventName, async (message, ...args) => {
			return new Promise(async (resolve, reject) => {
				try {
					// Envoie un callback au listener si il a deux paramètres (message, ack)
					if (listener.length == 2) {
						listener(message, (success) => success !== false ? resolve(success) : reject());
					}
					// Sinon attend la fin du listener
					else {
						let result = await listener(message);

						resolve(result);
					}
				} catch (err) {
					resolve(err);
				}
			});
		});

		return this;
	}


	/**
	 * Enregistre un listener pour écouter les erreurs venant du serveur du même scope que le broker.
	 * 
	 * @param {string} eventName 
	 * @param {listenerCallback} listener 
	 */
	error(eventName, listener) {
		return this.on(`error.${eventName}`, listener);
	}
}

Broker.instances = {};


Ingtech.Broker = Broker;